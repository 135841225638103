import cx from 'classnames';
import { useContext, useRef, useState, useEffect, useMemo } from 'react';

import { useHandleInternalLinks } from '@superhuit/starterpack-hooks';
import { SectionProvider, ThemeContext } from '@superhuit/starterpack-context';
import { omit } from '@superhuit/starterpack-utils';
import { LocaleContext } from '@superhuit/starterpack-i18n';

import Container from '@/next-components/container';
import {
	SectionMonthCalendar,
	SectionMonthCalendarPlaceholder,
	Filters,
	ResultsInfo,
} from '@/components/renders';
import { formatEventCalendar, formatFilters } from '@/lib/formaters';
import { sortTerms } from '@/lib/sort-terms';
import { CalendarPageThemeControls } from './theming';
import { getStyles } from './styles.css';

export default function CalendarPage({ node }) {
	const handleInternalLinks = useHandleInternalLinks();

	// Merge: defaults + theme (variant base + variant active)
	const { theme } = useContext(ThemeContext);
	const blockTheme = theme?.['templates']?.[CalendarPageThemeControls.slug]; // theme
	const finalTheme = omit(blockTheme, ['variants']);
	// console.log('::: Final Theme ::: ', finalTheme);

	const rootClass = cx('supt-calendarPage', getStyles(finalTheme));

	const monthsRef = useRef([]);

	const { __t, locale } = useContext(LocaleContext);

	const [filteredMonths, setFilteredMonths] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState([]); // Selected filters to filter the list with
	const [isProcessing, setIsProcessing] = useState(true);

	/**
	 * Format event dates + filters
	 * Group same event dates on calendar when there are multiple representations of the same show on the same day
	 */
	const { formatedEvents, filters } = useMemo(() => {
		const events = {};
		const genres = {},
			thematics = {};
		// publics = {};
		if (node.archive?.eventDates) {
			node.archive?.eventDates.forEach((perf) => {
				const fEvent = formatEventCalendar(perf, locale);
				const key = `${
					fEvent.id
				}${fEvent.date.getFullYear()}-${fEvent.date.getMonth()}-${fEvent.date.getDate()}`;

				if (events[key]) {
					// As the performances are correctly sorted, concatenate the times for a maximum of 3 performances
					if (events[key].nbPerfs < 3)
						events[key].time += ` / ${fEvent.time}`;
					else if (events[key].nbPerfs === 3)
						events[key].time += ' / ...';

					events[key].nbPerfs += 1;
				} else events[key] = { ...fEvent, nbPerfs: 1 };

				perf.event.genres.nodes.forEach(({ translation: t }) => {
					if (t && t.id && !genres[t.id]) genres[t.id] = t;
				});
				perf.event.thematics.nodes.forEach(({ translation: t }) => {
					if (t && t.id && !thematics[t.id]) thematics[t.id] = t;
				});
				// perf.event.publics.nodes.forEach(({translation: t}) => {
				// 	if (!publics[t.id]) publics[t.id] = t;
				// });
			});
		}

		return {
			formatedEvents: Object.values(events),
			filters: [
				{
					filter: 'genres',
					items: formatFilters(Object.values(genres).sort(sortTerms)),
				},
				{
					filter: 'thematics',
					items: formatFilters(
						Object.values(thematics).sort(sortTerms),
						true
					),
					link: node?.siteSettings?.pageForThematics
						? {
								href: node.siteSettings.pageForThematics.uri,
								title: __t(
									'thematics-filters-link',
									'Discover the themes'
								),
						  }
						: null,
				},
				// {
				// 	filter: 'publics',
				// 	items: formatFilters(
				// 		Object.values(publics).sort(sortTerms),
				// 		true
				// 	),
				// },
			],
		};
	}, [node.archive?.eventDates]);

	/**
	 * Pre-select filters if search query string is set on page load
	 */
	useEffect(() => {
		if (location.search === '') return;

		const searchParams = new URLSearchParams(location.search);
		const preSelectedFilters = [];

		searchParams.forEach((value, key) => {
			value.split(',').forEach((id) => {
				preSelectedFilters.push({
					filter: key,
					...(filters
						.find(({ filter }) => filter === key)
						?.items?.find((item) => item.id === id) ?? {}),
				});
			});
		});
		setSelectedFilters(preSelectedFilters);
	}, []);

	/**
	 * Filter events
	 */
	useEffect(() => {
		if (formatedEvents.length) {
			setIsProcessing(true);
			let _filteredMonths;
			if (!selectedFilters.length)
				_filteredMonths = groupEventsByMonth(formatedEvents);
			else {
				const selectedGFilters = selectedFilters.filter(
					({ filter }) => filter === 'genres'
				);
				const selectedTFilters = selectedFilters.filter(
					({ filter }) => filter === 'thematics'
				);
				// const selectedPFilters = selectedFilters.filter(
				// 	({ filter }) => filter === 'publics'
				// );

				const filteredEvents = formatedEvents.reduce((acc, event) => {
					const genresFilters =
						selectedGFilters.length > 0
							? event.genres.some((eventG) =>
									selectedGFilters.find(
										(g) => g.id === eventG.id
									)
							  )
							: true;
					const thematicsFilters =
						selectedTFilters.length > 0
							? event.thematics.some((eventT) =>
									selectedTFilters.find(
										(t) => t.id === eventT.id
									)
							  )
							: true;
					// const publicFilters =
					// 	selectedPFilters.length > 0
					// 		? event.publics.some((eventP) =>
					// 				selectedPFilters.find(
					// 					(p) => p.id === eventP.id
					// 				)
					// 		  )
					// 		: true;

					if (
						!!genresFilters &&
						!!thematicsFilters
						// && !!publicFilters
					) {
						acc.push(event);
					}

					return acc;
				}, []);

				_filteredMonths = groupEventsByMonth(filteredEvents);
			}

			setFilteredMonths(_filteredMonths);
			setIsProcessing(false);
		}
	}, [formatedEvents, selectedFilters]);

	/**
	 * Group events by month
	 * @param {*} events
	 * @returns
	 */
	const groupEventsByMonth = (events) => {
		return events.reduce((acc, event) => {
			// Get month + year of current event
			const eventMonth = event.date.toLocaleString(locale, {
				month: 'long',
				year: 'numeric',
			});

			// Find current event month+year in the calendar
			const currentMonthItemIndex = acc.findIndex(
				(m) => m.month === eventMonth
			);

			if (currentMonthItemIndex > -1) {
				// If there's already the current event month then push the event to its list of events
				acc[currentMonthItemIndex].events.push(event);
			} else {
				// If there isn't the current event month then push the new month and the event to its list of events
				acc.push({
					month: eventMonth,
					events: [event],
				});
			}

			return acc;
		}, []);
	};

	/**
	 * Update URL according to selected filters
	 */
	useEffect(() => {
		const queryArgs = selectedFilters.reduce((query, item) => {
			if (!query[item.filter]) query[item.filter] = [];
			query[item.filter].push(item.id);
			return query;
		}, {});

		const searchString = decodeURIComponent(
			new URLSearchParams(queryArgs).toString()
		);
		if (searchString !== location.search) {
			history.pushState(
				queryArgs,
				null,
				location.origin +
					location.pathname +
					(searchString !== '' ? '?' + searchString : '')
			);
		}
	}, [selectedFilters]);

	return (
		<>
			<Container className={rootClass} data-block="calendarPage">
				<div
					className="supt-calendarPage__inner"
					onClick={handleInternalLinks}
				>
					<SectionProvider variant="default">
						<Filters
							title={node.title}
							buttons={
								node.seasonCTA?.href
									? [
											{
												...node.seasonCTA,
											},
									  ]
									: []
							}
							filters={filters}
							selectedFilters={selectedFilters}
							onChange={(value) => setSelectedFilters(value)}
							withBorders={true}
						/>

						{/* List of months */}
						{isProcessing ? (
							<SectionMonthCalendarPlaceholder />
						) : filteredMonths.length > 0 ? (
							filteredMonths.map((month, index) => (
								<SectionMonthCalendar
									key={month.month}
									index={index}
									month={month.month}
									events={month.events}
									ref={(ref) =>
										(monthsRef.current[index] = ref)
									}
									monthsRef={monthsRef}
									selectedFilters={selectedFilters}
								/>
							))
						) : (
							<ResultsInfo
								number={0}
								onChange={(value) => setSelectedFilters(value)}
							></ResultsInfo>
						)}
					</SectionProvider>
				</div>
			</Container>
		</>
	);
}
